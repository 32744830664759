import React, { useState, useEffect, useRef } from 'react';
import { Camera, X, Utensils } from 'lucide-react';

interface Language {
  [key: string]: string;
}

const languages: Language = {
  'en': 'English',
  'zh': '中文',
  'es': 'Español',
  'fr': 'Français',
  'de': 'Deutsch',
  'ja': '日本語',
  'ko': '한국어',
};

interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  en: {
    menuTranslator: 'Menu Translator',
    captureMenuImages: 'Select Menu Images',
    clickToCapture: 'Click to select',
    orDragAndDrop: 'or drag and drop',
    translateMenu: 'Translate Menu',
    translating: 'Translating...',
    error: 'Error',
    selectLanguage: 'Select Language',
    translatedMenu: 'Translated Menu',
    removeImage: 'Remove image',
    selectedImages: 'Selected Images',
  },
  zh: {
    menuTranslator: '菜单翻译器',
    captureMenuImages: '选择菜单图片',
    clickToCapture: '点击选择',
    orDragAndDrop: '或拖放图片',
    translateMenu: '翻译菜单',
    translating: '翻译中...',
    error: '错误',
    selectLanguage: '选择语言',
    translatedMenu: '翻译后的菜单',
    removeImage: '删除图片',
    selectedImages: '已选择的图片',
  },
  es: {
    menuTranslator: 'Traductor de Menú',
    captureMenuImages: 'Seleccionar imágenes del menú',
    clickToCapture: 'Haz clic para seleccionar',
    orDragAndDrop: 'o arrastra y suelta',
    translateMenu: 'Traducir menú',
    translating: 'Traduciendo...',
    error: 'Error',
    selectLanguage: 'Seleccionar idioma',
    translatedMenu: 'Menú traducido',
    removeImage: 'Eliminar imagen',
    selectedImages: 'Imágenes seleccionadas',
  },
  fr: {
    menuTranslator: 'Traducteur de Menu',
    captureMenuImages: 'Sélectionner les images du menu',
    clickToCapture: 'Cliquez pour sélectionner',
    orDragAndDrop: 'ou glissez-déposez',
    translateMenu: 'Traduire le menu',
    translating: 'Traduction en cours...',
    error: 'Erreur',
    selectLanguage: 'Sélectionner la langue',
    translatedMenu: 'Menu traduit',
    removeImage: 'Supprimer l\'image',
    selectedImages: 'Images sélectionnées',
  },
  de: {
    menuTranslator: 'Menü-Übersetzer',
    captureMenuImages: 'Menübilder auswählen',
    clickToCapture: 'Klicken Sie zum Auswählen',
    orDragAndDrop: 'oder ziehen und ablegen',
    translateMenu: 'Menü übersetzen',
    translating: 'Übersetzung läuft...',
    error: 'Fehler',
    selectLanguage: 'Sprache auswählen',
    translatedMenu: 'Übersetztes Menü',
    removeImage: 'Bild entfernen',
    selectedImages: 'Ausgewählte Bilder',
  },
  ja: {
    menuTranslator: 'メニュー翻訳ツール',
    captureMenuImages: 'メニュー画像を選択',
    clickToCapture: 'クリックして選択',
    orDragAndDrop: 'またはドラッグ＆ドロップ',
    translateMenu: 'メニューを翻訳',
    translating: '翻訳中...',
    error: 'エラー',
    selectLanguage: '言語を選択',
    translatedMenu: '翻訳されたメニュー',
    removeImage: '画像を削除',
    selectedImages: '選択された画像',
  },
  ko: {
    menuTranslator: '메뉴 번역기',
    captureMenuImages: '메뉴 이미지 선택',
    clickToCapture: '클릭하여 선택',
    orDragAndDrop: '또는 드래그 앤 드롭',
    translateMenu: '메뉴 번역',
    translating: '번역 중...',
    error: '오류',
    selectLanguage: '언어 선택',
    translatedMenu: '번역된 메뉴',
    removeImage: '이미지 제거',
    selectedImages: '선택된 이미지',
  },
};

interface MenuItem {
  name_original: string;
  name_translated: string;
  price: string;
  description_original: string;
  description_translated: string;
  category_name_translated: string;
}

interface Category {
  name_original: string;
  name_translated: string;
}

interface Menu {
  is_menu: boolean;
  restaurant_name: string;
  category: Category[];
  items: MenuItem[];
}

const MenuTranslator: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [menu, setMenu] = useState<Menu | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const translatedMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const browserLang = navigator.language.split('-')[0];
    if (languages[browserLang]) {
      setLanguage(browserLang);
    }
  }, []);

  const t = (key: string): string => translations[language]?.[key] || translations['en'][key] || key;

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          if (typeof result === 'string') {
            const base64String = result.split(',')[1];
            setImages(prevImages => [...prevImages, base64String]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const removeImage = (index: number) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (images.length === 0) {
      setError(t('captureMenuImages'));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/translate_menu', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          images: images,
          target_language: languages[language],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to translate menu');
      }

      const data: Menu = await response.json();
      console.log('Received menu data:', data);
      setMenu(data);

      // Scroll to the translated menu after a short delay
      setTimeout(() => {
        if (translatedMenuRef.current) {
          translatedMenuRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-8">
          <div className="flex items-center justify-center mb-6">
            <Utensils className="w-8 h-8 text-blue-500 mr-2" />
            <h1 className="text-3xl font-bold text-gray-800">{t('menuTranslator')}</h1>
          </div>

          <div className="mb-6">
            <label htmlFor="language-select" className="block mb-2 text-sm font-medium text-gray-700">
              {t('selectLanguage')}
            </label>
            <select
              id="language-select"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              {Object.entries(languages).map(([code, name]) => (
                <option key={code} value={code}>{name}</option>
              ))}
            </select>
          </div>

          <div className="mb-6">
            <label htmlFor="capture" className="block mb-2 text-sm font-medium text-gray-700">
              {t('captureMenuImages')}
            </label>
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="capture"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 hover:bg-blue-100 transition duration-300 ease-in-out"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Camera className="w-12 h-12 mb-3 text-blue-500" />
                  <p className="mb-2 text-sm text-gray-500 text-center">
                    <span className="font-semibold">{t('clickToCapture')}</span><br />{t('orDragAndDrop')}
                  </p>
                </div>
                <input id="capture" type="file" accept="image/*" multiple className="hidden" onChange={handleCapture} />
              </label>
            </div>
          </div>

          {images.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-3 text-gray-700">{t('selectedImages')}:</h3>
              <div className="grid grid-cols-2 gap-4">
                {images.map((image, index) => (
                  <div key={index} className="relative group">
                    <img src={`data:image/jpeg;base64,${image}`} alt={`${t('selectedImages')} ${index + 1}`} className="w-full h-auto rounded-lg shadow-md" />
                    <button
                      className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out"
                      onClick={() => removeImage(index)}
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {images.length > 0 && (
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out"
              disabled={loading}
            >
              {loading ? t('translating') : t('translateMenu')}
            </button>
          )}

          {error && (
            <div className="mt-6 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded">
              <h4 className="font-bold">{t('error')}</h4>
              <p>{error}</p>
            </div>
          )}
        </div>

        {menu && (
          <div ref={translatedMenuRef} className="bg-gray-50 p-8 border-t border-gray-200">
            <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
              {menu.restaurant_name || t('translatedMenu')}
            </h2>
            {menu.category.map((cat, index) => (
              <div key={index} className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-gray-700 border-b border-gray-300 pb-2">{cat.name_translated}</h3>
                <ul className="space-y-4">
                  {menu.items
                    .filter(item => item.category_name_translated === cat.name_translated)
                    .map((item, itemIndex) => (
                      <li key={itemIndex} className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition duration-300 ease-in-out">
                        <div className="font-medium text-lg text-gray-800">{item.name_translated}</div>
                        <div className="text-xs text-gray-500 mt-1">{item.name_original}</div>
                        {item.description_translated && <div className="text-sm text-gray-600 mt-2">{item.description_translated}</div>}
                        {item.price && <div className="text-sm font-bold mt-2 text-blue-600">{item.price}</div>}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuTranslator;